import React from "react";
import { Description } from "./Description";
import { Link } from "./Links";
export const Works = ({ works }) => {
  return (
    <section className="work-experience section" id="experience">
      <h2 className="section-title">
        Experience
        </h2>
      <div className="experience__container bd-grid">
        {works.map((work, i) => (
          <Work key={i} {...work} />
        ))}
      </div>
    </section>
  );
};

const Work = ({ title, period, company, description, links}) => {
  const domain = window.location.hostname
  return (
    <div className="experience__content">
      <div className="experience__time">
        <span className="experience__rounder"></span>
        <span className="experience__line"></span>
      </div>
      <div className="experience__data bd-grid">
        <h3 className="experience__title">{title}</h3>
        <span className="experience__company">
          {period} | <a href={company.url}target="_blank" rel="noreferrer" className="organisation_link">{company.name}</a>
        </span>
        {description.map((desc,i) => <Description  key={i} desc={desc}/>)}
        <div >
          {links.map((link) => <Link key={link.url} label={link.label} url={link.url} className="bx-link"/>)}
        </div>

      </div>
    </div>
  );
};
